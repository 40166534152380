import { CoreMenu } from '@core/types';

//? DOC: http://localhost:7777/demo/vuexy-angular-admin-dashboard-template/documentation/guide/development/navigation-menus.html#interface

export const menu: CoreMenu[] = [
  // Dashboard
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'item',
    // role: ['Admin'], //? To hide collapsible based on user role
    icon: 'home',
    url: 'dashboard'
  },
  // {
  //   id: 'map',
  //   title: 'Map',
  //   icon: 'map',
  //   type: 'item',
  //   url: 'map'
  // },
  // {
  //   id: 'calendar',
  //   title: 'Calendar',
  //   translate: 'MENU.APPS.CALENDAR',
  //   type: 'item',
  //   icon: 'calendar',
  //   url: 'apps/calendar'
  // },
  {
    id: 'Properties',
    type: 'section',
    title: 'Properties',
    icon: 'package',
    children: [
      {
        id: 'elist',
        title: 'Add',
        type: 'item',
        icon: 'grid',
        url: 'property/property-add'
      },
      {
        id: 'elist',
        title: 'List',
        type: 'item',
        icon: 'layers',
        url: 'property/property-list'
      },
    ]
  },
  {
    id: 'Tenants',
    type: 'section',
    title: 'Tenants',
    icon: 'package',
    children: [
      {
        id: 'elist',
        title: 'Add',
        type: 'item',
        icon: 'user-plus',
        url: 'tenant/tenant-add'
      },
      {
        id: 'elist',
        title: 'List',
        type: 'item',
        icon: 'users',
        url: 'tenant/tenant-list'
      },
    ]
  },
  {
    id: 'Leases',
    type: 'section',
    title: 'Leases',
    icon: 'archive',
    children: [
      {
        id: 'elist',
        title: 'Add',
        type: 'item',
        icon: 'file-plus',
        url: 'lease/lease-add'
      },
      {
        id: 'elist',
        title: 'List',
        type: 'item',
        icon: 'file-text',
        url: 'lease/lease-list'
      },
    ]
  },
  {
    id: 'Payments',
    type: 'section',
    title: 'Payments',
    icon: 'archive',
    children: [
      {
        id: 'elist',
        title: 'Add',
        type: 'item',
        icon: 'file-plus',
        url: 'lease-payment/lease-payment-add'
      },
      {
        id: 'elist',
        title: 'List',
        type: 'item',
        icon: 'file-text',
        url: 'lease-payment/lease-payment-list'
      },
    ]
  },
  
  {
    id: 'Service Charges Received',
    type: 'section',
    title: 'Service Charges Received',
    icon: 'archive',
    children: [
      {
        id: 'elist',
        title: 'Add',
        type: 'item',
        icon: 'file-plus',
        url: 'service-charge/service-charge-add'
      },
      {
        id: 'elist',
        title: 'List',
        type: 'item',
        icon: 'file-text',
        url: 'service-charge/service-charge-list'
      },
    ]
  },
  {
    id: 'Reports',
    type: 'section',
    title: 'Reports',
    icon: 'file-text',
    children: [
      {
        id: 'getpropertydetails',
        title: 'Property Details',
        icon: 'file-text',
        type: 'item',
        url: 'report/property-details',
      },
      {
        id: 'generateservicechargesreport',
        title: 'Service Charges Received',
        icon: 'file-text',
        type: 'item',
        url: 'report/service-charges',
      },
      {
        id: 'lppalm-website-current',
        title: 'Current FY Lease Payments',
        icon: 'file-text',
        type: 'item',
        url: 'report/lease-payments-current-fy',
      },
      {
        id: 'lppalm-website-last',
        title: 'Last FY Lease Payments',
        icon: 'file-text',
        type: 'item',
        url: 'report/lease-payments-last-fy',
      },    
      {
        id: 'apalm-website',
        title: 'All Leases',
        icon: 'file-text',
        type: 'item',
        url: 'report/all-leases',
      },    
      {
        id: 'cpalm-website',
        title: 'Current Leases',
        icon: 'file-text',
        type: 'item',
        url: 'report/current-leases',
      },    
      {
        id: 'ex-website',
        title: 'Expired Leases',
        icon: 'file-text',
        type: 'item',
        url: 'report/expired-leases',
      },    
      {
        id: 'palm-website',
        title: 'Expiring Soon Leases',
        icon: 'file-text',
        type: 'item',
        url: 'report/expiring-leases',
      },    
      {
        id: 'palm-website',
        title: 'Upcoming Review Leases',
        icon: 'file-text',
        type: 'item',
        url: 'report/review-leases',
      },    
    ]
  },
  {
    id: 'External',
    type: 'section',
    title: 'External',
    icon: 'external-link',
    children: [
      {
        id: 'palm-website',
        title: 'Palm Council',
        icon: 'external-link',
        type: 'item',
        url: 'https://www.palmcouncil.qld.gov.au',
        externalUrl: true,
        openInNewTab: true
      },    
    ]
  },
];
