import { InMemoryDbService } from 'angular-in-memory-web-api';

import { accountSettingsFakeData } from '@fake-db/account-settings.data';
import { CalendarFakeData } from '@fake-db/calendar.data';
import { DatatableFakeData } from '@fake-db/datatables';
import { ProfileFakeData } from '@fake-db/profile.data';
import { UsersFakeData } from '@fake-db/users.data';

export class FakeDbService implements InMemoryDbService {
  createDb(): any {
    return {
      // Data-table
      'datatable-rows': DatatableFakeData.rows,

      // Account Settings
      'account-settings-data': accountSettingsFakeData.data,

      // Profile
      'profile-data': ProfileFakeData.data,

      // Users
      'users-data': UsersFakeData.users,
      'properties-data': UsersFakeData.properties,
      'tenants-data': UsersFakeData.tenants,
      'leases-data': UsersFakeData.leases,

      // Calendar
      'calendar-events': CalendarFakeData.events,
      'calendar-filter': CalendarFakeData.calendar,

      'notifications-data': [],
      'search-data': [],
      'dashboard-data': [],
    };
  }
}
